import moment from "moment";
import { TAgent, AGENT_TYPE } from "./types";

export const cachedUrl = function (url: string) {
  const parsedUrl = new URL(url)

  if (url.includes(import.meta.env.VITE_APP_SHARP_API_URL!)) {
    return url
  }
  // parsedUrl.pathname
  return url.includes("/wp-content/") ? `${import.meta.env.VITE_APP_SHARP_API_URL}/wp-media/${parsedUrl.pathname}` : `${import.meta.env.VITE_APP_SHARP_API_URL}/get-media/${parsedUrl.pathname}`
}
export function authHeader() {
  return localStorage.getItem("_auth");
}

/**
 * Check if the message sender is the user 
 * @param msg 
 * @param user 
 * @returns Returns true if the message sender is the currently logged in user
 */
export function checkMessageSender(msg: any, user: any) {
  if (!user || !msg) return false;
  return msg.sender === user.user_email && msg.destination !== user.user_email;
}
export function extractIdsAndTexts(inputString) {
  const regex = /@\[([^\]]+)\]\(([^)]+)\) ([^.]+)/g
  const matches = inputString.matchAll(regex)
  const result = [] as any[]

  for (const match of matches) {
    const id = match[2]
    const text = match[3].trim()
    result.push({ id, text })
  }

  return result
}
export function findAgentFromContent(content: string, agents: TAgent[]) {
  const agent = agents.find(agent => content.includes(agent.name))
  // find the session_agent
  if (!agent) {
    return agents.find(agent =>
      agent.agentType === AGENT_TYPE.SESSION_AGENT ||
      agent.agentType === AGENT_TYPE.SHARED_AGENT ||
      agent.agentType === AGENT_TYPE.CUSTOM_AGENT)
  }
  return agent
}


export function calendarTime(timeStr: string) {
  return moment(timeStr).calendar({
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: 'DD/MM/YYYY',
    sameElse: 'DD/MM/YYYY'
  })
}

export const gotoEnd = () => {

  document.querySelector(".message-end")?.scrollIntoView();

}

export const getAgentEmail = (agentName) => {
  return `${agentName}@tohju.com`;
};