import { nodeAxiosInstance } from "./http/nodeAxiosInstance";
import { TMessage } from "../lib/types";

const NEW_MSGS_POLL_INTV = import.meta.env.VITE_APP_NEW_MSGS_POLL_INTV &&
  parseInt(import.meta.env.VITE_APP_NEW_MSGS_POLL_INTV) ?
  parseInt(import.meta.env.VITE_APP_NEW_MSGS_POLL_INTV) :
  5000

export const getUserMessages = async (after: string) => {
  const response = await nodeAxiosInstance.get(`/chat/conversation?after=${after}`)

  if (response.data) {
    return response.data as TMessage[];
  }

  throw new Error(response.data)
}

export const pollMessages = async (onNewMessages: (messages: TMessage[]) => void, after = '', keepLoop = false) => {

  const int = setInterval(async () => {
    const newMessages = await getUserMessages(after).catch(err => console.log(err))
    if (newMessages && newMessages.length) {
      onNewMessages(newMessages)
      if (!keepLoop) {
        clearInterval(int)
      }
    }
  }, NEW_MSGS_POLL_INTV)
}

export const postUserMessages = async (content: string) => {
  const response = await nodeAxiosInstance.post(`/chat/messages`, { content })

  if (response.data) {
    return response.data;
  }

  throw new Error(response.data)
}


export const uploadUserDoc = async (files: any[], sessionId: string) => {
  // send upload request to wordpress using fetch and formdata
  const formData = new FormData();

  // formData.set('file', file);
  Array.from(files).forEach((file) => {
    formData.append('files', file);
  });
  formData.set('sessionId', sessionId);
  const response = await nodeAxiosInstance.post(`/api/chat/upload`, formData)

  return response.data

};

export const fetchCodeIntent = async (payload: {
  amount: number,
  messageId: string
}) => {
  const response = await nodeAxiosInstance.post(`/api/log/create-intent`, payload)

  return response.data
}

export const shareSession = async (sessionId: string, selectedAgents, accessTo?: string) => {
  const response = await nodeAxiosInstance.post(`/api/shares/session`, { sessionId, accessTo, selectedAgents })

  return response.data
}

export const deletePublicShare = async (sessionId) => {
  const response = await nodeAxiosInstance.delete(`/api/shares/session/public/${sessionId}`)

  return response.data
}

export const getPublicShare = async (sessionId) => {
  const response = await nodeAxiosInstance.get(`/api/shares/session/public/${sessionId}`)

  return response.data
}

export const getShareSession = async (sessionId) => {
  const response = await nodeAxiosInstance.get(`/api/shares/session/${sessionId}`)

  return response.data
}

export const getMySharedSession = async (sessionId) => {
  const response = await nodeAxiosInstance.get(`/api/sessions/mine/${sessionId}`)

  return response.data
}


export const createEmbeddingReq = async (messageId, document, sessionId) => {
  const response = await nodeAxiosInstance.post(`/api/chat/create-embedding`, { document, messageId, sessionId })

  return response.data
}